<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                回馈科教，非凸科技助力第48届ICPC亚洲区决赛
              </p>
              <div class="fst-italic mb-2">2024年1月17日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >ICPC</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/71/01-ICPC.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    1月12日-14日，“华为杯”第48届国际大学生程序设计竞赛（ICPC）亚洲区决赛在上海大学成功举办。非凸科技作为此次赛事的支持方之一，希望携手各方共同推动计算机科学和技术的发展。
                  </p>
                  <p>
                    这是一场智慧的巅峰对决，320支优秀队伍，汇聚一堂，强强博弈。比赛中，参赛选手们屏息凝神，专注题目，手指在键盘上飞快地跳跃，编写着一行行精妙的代码。他们除了需要具备扎实的专业水平外，良好的团队协作和心理素质同样是成功的关键。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/71/02-获奖.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    经过三天的激烈角逐，28支队伍获得金奖，56支队伍获得银奖，84支队伍获得铜奖。其中，清华大学“一路向北”队荣获冠军，北京大学“摆烂人”队获得亚军，电子科技大学“UESTC_Guest_WiFi”队获得季军。恭喜所有获奖队伍，让大家看到了当代青年人的技术热情、不惧挑战。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/71/03-企业宣讲.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    非凸科技作为ICPC赛事支持方进行了企业宣讲，旨在通过近距离的交流互动，让参赛选手更直观地了解公司的发展方向、技术优势及人才培养等方面。欢迎所有的学子人才来到非凸科技，体验技术至上的工作氛围，迎接挑战，更快更好地成长。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/71/04-闭幕演讲.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    在闭幕式上，非凸科技策略研究负责人陆一洲发表了主题演讲。他分享了自身在ACM与数智交易领域的成长心路，强调了从解决算法难题到挑战金融问题的转变，以及带来的快乐与成就。同时，鼓励年轻人积极投身于热爱的技术领域，大胆创新，勇于创新，期待共同用技术构建一个更加美好的世界。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/71/05-比赛现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    未来，非凸科技将继续用实际行动回馈科教事业，激发更多学生学习计算机的热情，为国家计算机学科人才培养贡献一份力量。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News71",
};
</script>

<style></style>
